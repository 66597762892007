@import './variables.scss';


/*

html{
    scroll-behavior: smooth;
*/

.loading-overlay {
    position: fixed;
    display: initial;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100vw;
    height: 100vh;
    background-color: rgba(255, 255, 255, .4);
    overflow: hidden;
    z-index: 1002;


    .loading-animation {
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        margin: auto;
        max-width: 100px;
        max-height: 100px;
        background-color: transparent;
    }
}

.hs-form {
    position: relative;
    display: flex;
    flex-direction: row;
    width: 100%;
    flex-wrap: wrap;
    justify-content: space-between;
    padding-top: 44px;
    text-align: left;
    max-width: 890px;
    margin: 0 auto;
    font-size: 16px;
    line-height: 21px;
    font-family: Miera !important;

    .hs-form-field {
        width: calc(50% - 12px);
        display: flex;
        flex: 0 1 auto;
        box-sizing: border-box;
        flex-direction: column;

        &.hs-fieldtype-textarea {
            width: 100%;
        }
    }
    .hs-dependent-field{
        width: 100%;
        display: flex;
        flex-direction: row;
        flex: 0 1 auto;
        justify-content: space-between;

        .inputs-list.multi-container{
            list-style-type: none;
            display: flex;
            flex-direction: row;
            column-gap: 12px;
            padding-left: 0;
        }
    }

    input[type=text],
    input[type=email],
    textarea,
    select {
        border: solid $black-10 1px;
        border-radius: 4px;
        position: relative;
        width: 100%;
        max-width: 100%;
        height: 40px;
        padding: 4px 8px !important;
        box-sizing: border-box;
        margin: 4px 0 12px;

    }


    textarea {
        height: 144px;
        padding: 16px !important
    }

    .hs-submit {
        margin: 0 auto;
        display: flex;
        width: 100%;
        justify-content: center;
    }

    .hs-button {
        width: 137px;
        height: 51px;
        margin: 36px auto 0;
        font-size: 20px;
        line-height: 27px;
        font-weight: 700;
        border: 1px solid $blue-10;
        color: $blue-10;
        font-family: Miera !important;
        box-sizing: border-box;
        border-radius: 4px;
        background-color: transparent;
        padding: 0 !important;
        box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.12);

        &:hover,
        &:focus {
            background-color: $blue-10;
            color: $white-10;
        }
    }

    .hs-error-msgs {
        color: #FF5862;
    }
}

#hubspot-messages-iframe-container {
    margin-bottom: 24px;
}

.af-class-footerlink {
    font-family: "MierA";
    cursor: pointer;

    &:hover {
        color: $black-10 !important;
    }
}

button{
    cursor: pointer;
}

@media screen and (min-width: 0) and (max-width:$mobile) {
    .hs-form {
        flex-direction: column;
        flex: 0 1 auto;
        max-width: unset;

        .hs-form-field {
            width: 100%;
        }

        .hs-dependent-field{
            flex-direction: column;
        }

        input[type=text],
        input[type=email],
        textarea,
        select {
            border: none;
            position: relative;
            width: 100%;
            max-width: 100%;
            height: 40px;
            padding: 4px 8px !important;
            -webkit-box-sizing: border-box;
            box-sizing: border-box;
        }

        textarea {
            height: 112px;
            padding: 12px !important
        }

        .hs-button {
            width: 50%;
            min-height: 51px;
            height: fit-content;
            margin: 36px auto 0;
            font-size: 20px;
            line-height: 27px;
            font-weight: 700;
            border: 1px solid $blue-12;
            color: $white-10;
            font-family: Miera !important;
            box-sizing: border-box;
            border-radius: 4px;
            background-color: $blue-10;
            padding: 0 !important;
            box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.12);
            margin: 8px auto 0;
            align-self: center;
            display: block;

            &:hover,
            &:focus {
                background-color: $blue-10;
                color: $white-10;
            }
        }
    }

    .w-col-6{
        width: calc(100% - 32px) !important;
    }
}


@media screen and (min-width: 0) and (max-width: $tablet) {

    #hubspot-messages-iframe-container.widget-align-right{
        margin-right: 0;
        margin-bottom: 8px;
        display: none !important;
    }
}