@import './variables.scss';
@import './base.scss';

.no-scroll {
    height: calc(100vh - 20px) !important;
    overflow: hidden;
}

.af-class-quote-view {
    padding-bottom: 0 !important;
}
.af-class-text-block-73{
    &:hover{
        color: $blue-10 !important;
        font-weight: 700;
    }
}
.link-button{
        &:hover{
        cursor: pointer;
        color: $blue-12 !important;
    }
}

.promo-body{
    color: $green-10 !important;
    font-weight: 700;
}

.promo-container{
    margin-top: 14px;
    display: flex;
    width: 100%;
    column-gap: 14px;
    font-family: "Miera book";

    .promo-input{
        border-radius: 4px;
        padding: 8px 12px;
        font-size: 20px;
        line-height: 20px;
        box-shadow: rgba(0, 0, 0, 0.2) 0px 4px 12px 0px;
        border: 1px solid $gray-9;
        text-transform: uppercase;
        &::placeholder{
            color: $gray-9;
            text-transform: none;
        }
    
        &:focus-visible{
            outline-color: $blue-10 !important ;
        }
    }

    .promo-button{
        background-color: $blue-9;
        color: $white-10;
        border-radius: 4px;
        border: 1px $blue-10 solid;
        font-size: 20px;
        line-height: 27px;
        padding: 10px 16px 14px;
    }

    .disabled{
        pointer-events: none;
        background-color: $gray-8;
        border-color: $gray-10;
        color: $black-10;
    }
}

.promo-error{
    margin-top: 4px;
    color: $red-10;
    font-family: "Miera book";
}

.card-container {
    position: relative;
    width: 100%;
    height: fit-content;
    display: flex;
    flex-direction: row;
    column-gap: 20px;
    row-gap: 20px;
    flex-wrap: wrap;

    .card {
        position: relative;
        display: flex;
        flex-direction: column;
        min-width: calc(50% - 10px);
        width: 0%;
        flex: 1 0 auto;
        padding: 17px 22px 18px;
        min-height: 236px;
        background: $white-11;
        color: $gray-11;
        border: 1px solid $gray-11;
        border-radius: 8px;

        &:first-child {
            width: 100%;
        }

        &:hover,
        &.selected {
            color: $blue-10;
            border: 4px solid $blue-10;
            padding: 14px 19px 15px;
            cursor: pointer
        }

        .card-header {
          //  @extend h4;
            padding-bottom: 8px;
        }

        .card-body {
         //   @extend .c1;

        }

        .card-footer {
            display: flex;
            flex-direction: row;
            flex-wrap: nowrap;
            width: 100%;
            margin-top: auto;
            padding-top: 20px;

            .card-value {
             //   @extend .c2;
                font-weight: 700 !important;
                margin-left: auto;
            }

            .recommended-label {
            //    @extend .c2;
                font-weight: 700 !important;
            }
        }
    }
}

.radio-container {
    position: relative;
    display: flex;
    flex-direction: column;
    width: 100%;
    height: fit-content;
    row-gap: 8px;
    border: none !important;

    .radio-item {
        position: relative;
        width: 100%;
        border-radius: 8px;
        border: 4px solid $white-10;
        background-color: $white-10;
        color: $gray-11;
        padding: 16px 36px;
        box-sizing: border-box;
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        column-gap: 8px;
        align-self: center;

        &:checked,
        &:hover,
        &.selected {
            cursor: pointer;
            label {
                color: $blue-10;
                font-weight: 700;
            }

            border-color: $blue-10;
        }

        label {
            margin-bottom: 0;
        }

        .price-label {
            display: flex;
            margin-left: auto;
        }
    }
}

.af-class-container-20.w-container, .card-container, .radio-container{
    padding-bottom: 40px !important;
}



@media screen and (min-width: 0) and (max-width: $mobile) {


    .af-class-quote-view {

        .af-class-card-03.af-class-_50-p.af-class-r1.af-class-_80{
            margin-bottom: 48px;
        }

        .w-nav-button {
            display: none !important;
        }

        padding-bottom: 90px;

        .w-col-6 {
            width: 100% !important;
        }

        .card-container {
            flex-direction: column;

            .card {
                min-width: 100%;
            }
        }

        .radio-container {
            margin: 0;
            padding: 0;

            .radio-item {
                padding: 16px;
            }
        }

        .af-class-sticky-footer {
            justify-content: space-between !important;
            padding: 0 !important;
            margin-bottom: 20.5px !important;

            .af-class-new-total{
                color: $black-10 !important;
            }

            .af-class-sticky-footer-info {
                max-width: unset;
                padding: 18px 20px !important;
                width: 100%;
                justify-content: space-between;
                color: $black-10;
            }

            .af-class-blue-button-2 {
                height: fit-content !important;
                width: fit-content !important;
                display: flex !important;
                align-self: center;
                flex: 1 0 auto !important;
                margin: 0 !important;
                text-align: center !important;
            }

            .af-class-white-button {
                display: none !important;
            }
        }
    }

    .af-class-container-20.w-container, .card-container, .radio-container {
        padding-bottom: 18px !important;
    }

        .w-row{
            padding-top: 18px !important;
        }

    .af-view .af-class-div-title-holder.af-class-image.af-class-_02.af-class-top{
        margin-top: 0 !important;
    }

    .promo-container{
        .promo-input{
            flex-grow: 1;
            font-size: 16px;
            line-height: 16px;
        }
        .promo-button{
            font-size: 16px;
        }
    }

    .promo-error{
        font-size: 16px;
    }
}