@import './variables.scss';
.toast-container{
    display: flex;
    position: fixed;
    z-index: 1002;
    bottom: 20px;
    right: 0px;

    width: 424px;
    height: fit-content;
    flex-direction: column;
    overflow: hidden;

    .toast-component{
        position: relative;
        display: flex;
        width: 400px;
        height: fit-content;
        padding: 12px 16px 24px;
        box-sizing: border-box;
        left: 424px;
        margin-bottom: 24px;
        background-color: $white-10;
        filter: $small-shadow;
        border: 1px solid $gray-9;
        border-radius: 4px;
        flex-direction: column;

        .toast-header{
            display: flex;
            flex-direction: row;
            flex-wrap: nowrap;
            width: 100%;
            align-items: center;

            img{
                width: 18px;
                height: 18px;
                margin-right: 8px;
            }
        }

        .p2{
            margin-top: 4px;
        }

        &.success{
            animation: successToast ease-in;
            animation-duration: 4s;
            .toast-header{
                color: $green-10;
            }
        }

        &.info{
            animation: successToast ease-in;
            animation-duration: 4s;
            .toast-header{
                color: $blue-10;
            }
        }

        &.error{
            animation: errorToast ease-in;
            animation-duration: 8s;
            .toast-header{
                color: $red-9;
            }
        }

        .toast-message{
            width: 100%;
            color: $black-8;
        }
    }

    @media screen and (min-width: 0) and (max-width: $mobile) {
        width: 100%;
        padding-left: 5%;
        box-sizing: border-box;

        .toast-component{
            width: 95%;
        }
    }
}

@keyframes successToast {
    0%{
        left: 100%;
    }
    10%{
        left: 0px;
    }
    90%{
        left: 0px;
    }   
    100%{
        left: 100%;
    }

}

@keyframes errorToast {
    0%{
        left: 100%;
    }
    5%{
        left: 0px;
    }
    95%{
        left: 0px;
    }   
    100%{
        left: 100%;
    }

}