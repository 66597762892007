@import './variables.scss';
@import './base.scss';

.af-class-success-view{
    height: 100vh !important;
    margin: 0;
}

.af-view .af-class-section-12 {
    min-height: unset !important;
}

.w-nav-button {
    display: none !important;
}

.download-quote{
    display: flex !important;
}

@media screen and (min-width: 0) and (max-width: $mobile) {
    .af-view .af-class-section-12{
        padding-top: 0px !important;
    }
}