@import './variables.scss';

.contact-popup, .contact-inline-form{
    .header-content{
        margin: 0 32px;
    }
    .contact-background{
        position: fixed;
        top:0;
        left:0;
        width: 100%;
        height: 100%;
        background-color: rgba(1,1,1,.3);
        z-index: 0;
    }

    .contact-form{
        position: relative;
        display: flex;
        flex-direction: column;
        width: 66%;
        max-width: 900px;
        height: fit-content;
        padding: 48px;
        box-sizing: border-box;
        background-color: $white-11;
        z-index: 1;
        justify-content: center;
        align-items: center;
        border-radius: 4px;
        overflow-y: scroll;
        margin: auto;

        .contact-header{
            position: relative;
            display: flex;
            flex-direction: row;
            justify-content: center;
            text-align: center;
            align-items: flex-start;

            img{
                position: relative;
                height: 75px;
                width: 75px;
                padding-top: 16px;
                filter: $drop-shadow;
            }

            .header-row{
                padding-bottom: 0 48px 48px;
                display: flex;
                flex-direction: row;
                h2{
                    font-weight: 700;
                }
                .header-content{
                }
            }
        }

        .success-content{
            margin-bottom: 16px;
            display: none;
            img{
                filter: $drop-shadow;
            }

        }
    }

    @media screen and (min-width: 0) and (max-width: $mobile) {

        .contact-form{
            width: calc(100% - 32px);
            padding: 24px;

            div{
                width: 100%;
            }

            .contact-header img{
                display: none;
            }
        }
    } 
}

.contact-popup{
    top: 0;
    left: 0;
    position: fixed;
    z-index: 1001;
    width: 100vw;
    height: 100vh;
    border-radius: 4px;
    overflow-y: scroll;
    padding: 96px 0;
    box-sizing: border-box;
}

.contact-inline-form{
    position: relative;
    width: 100%;
    height: fit-content;
    background-color: $white-10;
}